<template>
  <div class="newsDetail">
    <!-- p1-->
    <section class="newsDetail-section-1">
      <img class="sec-1-img-1" src="../../assets/images/words@2x.png" alt="">
      <div class="newsDetail-content">
        <div class="content-title">{{ detail.title }}</div>
        <div class="content-time">{{ detail.createTime | formatTime }}</div>
        <div class="content-item" v-for="(item, index) in detail.content" :key="index">
          <div class="item-text" v-if="item.type === 'text'">{{ item.value }}</div>
          <div class="item-img" v-if="item.type === 'img'">
            <img :src="item.value" alt="">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getQueryByName } from '../../common/utils'
import * as api from '../../common/api'
import dayjs from 'dayjs'

export default {
  data () {
    return {
      img1: 'https://dl.botifyrobot.com/website/news/20210330/news_1.png',
      img2: 'https://dl.botifyrobot.com/website/news/20210330/news_2.png',
      img3: 'https://dl.botifyrobot.com/website/news/20210330/news_3.png',
      list: [
        {
          id: 1,
          title: '科普：清洁机器人百科',
          time: '2021.03.29 14:22:00',
          content: [
            {
              type: 'text',
              value: '清洁机器人，能够对室内或室外环境进行自动清洁工作，具有环境识别、路径规划、自主导航、智能避障、自动清洁等功能，可替代传统的人工清扫方式完成清洁作业，降低了热恩公劳动强度，提高了清扫覆盖率和清洁效能。'
            },
            {
              type: 'text',
              value: '清洁机器人涉及的核心技术主要包括：低速自动驾驶技术和机器人运动交互技术。'
            },
            {
              type: 'img',
              value: 'https://dl.botifyrobot.com/website/news/20210330/news_1.png',
            },
            {
              type: 'text',
              value: '低速自动驾驶技术：利用视觉、光学、惯性等多种传感器感知环境信息，通过SLAM和深度学习技术，使机器人可以在封闭或半封闭环境中，完成自主运动控制。'
            },
            {
              type: 'text',
              value: '机器人运动交互技术：通过对环境抽象建模及大面积环境场景地图构建，使清洁机器人可以满足跨场景的应用需求。 除此之外，涉及的共性技术还包括路径规划、智能避障、无线通讯网络技术等。'
            },
            {
              type: 'text',
              value: '清洁机器人的主要应用场景为公寓、酒店、商场、机场、大型展馆等公共场所以及室外道路。'
            },
            {
              type: 'text',
              value: '清扫功能:对像沙发下、床下这样不易清扫的区域确实有很强的清扫作用，它能够轻松将毛发、灰尘等垃圾清理干净;但是在家具密集的空间中工作时有点力不从心，要么卡在餐桌和餐椅之间，要么在餐桌下迷了路，十几分钟都转不出来。'
            },
            {
              type: 'text',
              value: '清扫效果：对地板上较薄浮尘的清扫效果较好，对于头发和较小的纸屑等垃圾，机器人可以轻易打扫干净；但是对于较厚的灰尘层效果则不尽如人意，还有对于较大的纸片，则会卡在机器人收集垃圾的通道内，必须手工清除。'
            },
            {
              type: 'text',
              value: '避障功能：不用人工操作，能够自动避开障碍物进行清扫，可轻易避开墙壁、家具等大型障碍物，基本不会发生碰撞；但是对于桌腿、家具腿、矿泉水瓶等目标较小的障碍物，很容易撞上，而且无法避开居室中的电源线。'
            },
            {
              type: 'text',
              value: '随着中国清洁行业和环卫市场的快速发展，清洁机器人通过其无人驾驶技术、智能化识别技术的迭代升级，其智能化、高效化、以及低成本替代人工的价值将会得到充分发挥，在商用和工业场景的使用率也将进一步提升。例如，通过与传统环卫行业的清洁车、清运车、垃圾处理车等多种设备结合，精准的定位环卫行业的各个角落，催生出多种功能型清洁机器人产品；通过提升环境识别能力，优化地图构建算法，实现对复杂环境物理空间信息的深度识别，实施针对性的清洁策略，大大提高复杂环境的适应和清扫能力；针对高楼幕墙、管道、医疗机构等特定应用场景，设计特定结构，优化人工智能和自动清洁等算法，使清洁机器人能够在特殊甚至危险环境中代替人类完成作业。'
            },
          ],
        },
        {
          id: 2,
          title: '科普：扫地机人的工作原理',
          time: '2021.03.29 14:22:00',
          content: [
            {
              type: 'img',
              value: 'https://dl.botifyrobot.com/website/news/20210330/news_2.png',
            },
            {
              type: 'text',
              value: '扫地机器人涉及多项技术，比如路径规划、传感器、定位建图算法、清扫、吸力、扫拖一体等等……按照行走方式可以分为两大类：随机碰撞式和路径规划式。'
            },
            {
              type: 'text',
              value: '第一类是早期且技术较为低端的随机碰撞式，常用的技术是红外线。这类技术只能告诉机器人“我前面是否有物体”，碰到物体就躲开，却不能对房间结构“胸有成竹”。就像一个带上遮眼罩的孩子被领到一个陌生的房间，伸出手臂摸索。他的手臂（避障）会保护他，但他却很难搞清楚房间的构造，且要花非常长的时间，才能走遍房间的每一个角落，清洁力度也不够，但好处是成本较低。'
            },
            {
              type: 'text',
              value: '第二类是路径规划式，主流技术有两种：激光导航和视觉导航。激光、摄像头等传感器就是它们的“眼睛”，边行走边为房间描绘出一幅较精准的地图。就像一个孩子到了陌生的房间，掀开了遮眼罩，经过多次行走和学习，终于对房间结构了如指掌，往后的清扫工作也更精确方便了。激光导航的优点是精度高，缺点是成本高；视觉导航的优点是数据更丰富，成本较低，缺点是在光线不足的场景，比如夜晚使用不便。'
            },
            {
              type: 'text',
              value: '值得一提的是，激光导航和视觉导航有融合趋势。大品牌通常都能覆盖以上两种产品线。比如中国的科沃斯，就以双路导航著称。'
            },
          ],
        },
        {
          id: 3,
          title: '商用清洁机器人----无限潜力的商业蓝海！',
          time: '2021.03.29 14:22:00',
          content: [
            {
              type: 'img',
              value: 'https://dl.botifyrobot.com/website/news/20210330/news_3.png',
            },
            {
              type: 'text',
              value: '商用清洁机器人：未完全开发市场潜力'
            },
            {
              type: 'text',
              value: '商用清洁机器人是服务机器人的一种，通过传感器、SLAM（定位与建图）、路径规划、运动控制四大环节，在无人的情况下，自主完成清洁工作。因此，商用清洁机器人又有“无人驾驶清扫车”、“无人洗地机”之称。'
            },
            {
              type: 'text',
              value: '商用清洁机器人的使用场所多是附加值更高的场所，比如机场、商场、景区、度假村、大学、美术馆、地产、医院、工业园区、生产车间、物流仓库等。在人力成本越高的地方，清洁机器人越受欢迎。'
            },
            {
              type: 'text',
              value: '据《2016－2021年中国清洁行业市场需求与投资咨询报告》显示，商业清洁行业总体市场规模主要包括清洁产品、清洁解决方案、清洁服务，其中人员服务占总规模的68．5％，清洁产品及其他物料市场越占31．5％；当前清洁行业还是以人工为主，机器为辅。商用清洁机器人当前处于行业拐点，商用潜力待开发。'
            },
            {
              type: 'text',
              value: '从表格上可以看到，当前商用清洁机器人企业数量较少，远远不及消费类扫地机器人，且企业集中在华南。但是，清洁是高频刚需，2016年，商业清洁行业总体市场规模达到了981亿，未来6年年复合增长率预计保持在6．6％。在清洁领域，要实现大规模“机器换人”，还需市场参与者共同努力，把行业做强做大。'
            },
          ],
        }
      ],
      detail: {
        title: '',
        time: '',
        content: []
      }
    }
  },
  filters: {
    formatTime: value => {
      if (!value) return ''
      return dayjs(value).format('YYYY年MM月DD日')
    },
  },
  created() {
    this.getDetail()
  },
  methods: {
    async getDetail () {
      const id = getQueryByName('id')
      if (id && id !== 0 && id !== null) {
        const result = await api.detail({
          uid: id
        })
        this.detail = result
      }
    },
  },
}
</script>

<style lang="less" scope>
// 大屏样式
@media (min-width: 1001px) {
  .newsDetail {
    // p1 css
    .newsDetail-section-1 {
      min-height: calc(100vh - 5.6rem);
      position: relative;

      .sec-1-img-1 {
        position: absolute;
        width: .98rem;
        height: 1.16rem;
        left: 1.51rem;
        top: .15rem;
      }

      .newsDetail-content {
        width: 10.7rem;
        margin: 0 auto 1.62rem auto;
        padding-top: .82rem;

        .content-title {
          font-size: .38rem;
          color: #F2F3F4;
        }

        .content-time {
          color: #C0C2C4;
          font-size: .28rem;
          padding: .45rem 0 .6rem 0;
        }

        .content-item {
          margin: .23rem 0;
          .item-text {
            font-size: .16rem;
            color: #C0C2C4;
            line-height: .28rem;
          }

          .item-img {
            max-width: 10.7rem;
            img {
              width: 100%;
              height: auto;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}
// 小屏样式
@media (max-width: 1000px) {
  .newsDetail {
    // p1 css
    .newsDetail-section-1 {
      position: relative;
      min-height: calc(100vh - 5rem);
      .sec-1-img-1 {
        width: .98rem;
        height: 1.16rem;
        padding-top: .12rem;
        padding-left: .4rem;
      }

      .newsDetail-content {
        width: 6.7rem;
        margin: 0 auto 1.62rem auto;
        padding-top: .42rem;

        .content-title {
          font-size: .38rem;
          color: #F2F3F4;
        }

        .content-time {
          color: #C0C2C4;
          font-size: .28rem;
          padding: .45rem 0 .3rem 0;
        }

        .content-item {
          margin: .23rem 0;
          .item-text {
            font-size: .22rem;
            color: #C0C2C4;
            line-height: .48rem;
          }

          .item-img {
            max-width: 6.7rem;
            img {
              width: 100%;
              height: auto;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}
</style>